import BaseTemplate from '../src/layouts/BaseTemplate'
import ����������ƵView from '../src/view/_public/����������ƵView';


export default function ����������Ƶ(props) {
  // const { data, status } = useQuery('users', getUsers, { initialData: props.users })
  // console.log("data", data)
  // console.log("status", status)

  // console.log("homeprops test", props)
  
  return (
    <BaseTemplate title="����������Ƶ" body={<����������ƵView />} />
    // <BaseTemplate title="����������Ƶ" body={<Dashboard />} />
  )
}
