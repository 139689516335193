import { useEffect, useState, useRef } from 'react'
import React from 'react'
import { ReactSVG } from 'react-svg'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { KTGet, KTPost } from '@/kyna/lib/db'
import toast from 'react-hot-toast'
import store from 'store2'
import { format, subDays } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { v4 as uuid } from 'uuid'
import { isEmpty } from 'lodash'


import Spinner from '@/kyna/ui/preloader/Spinner'
import AboutUs from '../../../public/assets/img/about-us.png'
import Service1 from '../../../public/assets/img/SVGServices01.svg'
import Service2 from '../../../public/assets/img/SVGServices02.svg'
import HeaderNav from '../../includes/HeaderNav'
import Footer from '../../includes/Footer'
import KNToaster from '@/kyna/ui/toaster/KNToaster'
import KNDatePicker from '@/kyna/ui/datepicker'
import { KNThemeError } from '@/kyna/ui/toaster/KNTheme'
import Stats from './Stats'

import ImageSlider from '../../ui/carousel/ImageSlider'


import ����������ƵPhoto1 from '../../../public/assets/img/pmha-photo-1.jpg'
import ����������ƵPhoto2 from '../../../public/assets/img/pmha-photo-2.jpg'
import ����������ƵPhoto3 from '../../../public/assets/img/pmha-photo-3.jpg'
import ����������ƵPhoto4 from '../../../public/assets/img/pmha-photo-4.jpg'
import ����������ƵPhoto5 from '../../../public/assets/img/pmha-photo-5.jpg'
import ����������ƵPhoto6 from '../../../public/assets/img/pmha-photo-6.jpg'
import Slider1 from './Slider1'

const ����������ƵView = () => {
  const [availableTimeSlot, setAvailableTimeSlot] = useState([])
  const [availableDates, setAvailableDates] = useState([])
  const [scheduleID, setScheduleID] = useState([])
  const [dateIsLoading, setDateIsLoading] = useState(false)
  const [isTimeSlotLoading, setTimeSlotLoading] = useState(false)
  const [booking, setBooking] = useState({
    service: null,
    date: null
  })
  const router = useRouter()
  const timeRef = useRef()
  const {
    data: dateData,
    isLoading: slotIsLoading,
    refetch: dateRefetch,
    isFetching,
    status: slotStatus
  } = useQuery(
    'Slot',
    () =>
      booking.service &&
      KTGet('api/doctors/', {
        where: `service_id:${booking.service}`
      }),
    { enabled: false }
  )
  const getServiceCategory = async category => {
    return await KTGet('api/maintenance/_service', {
      orderBy: 'modified,asc',
      category: category
    })
  }
  const { data: AdultData } = useQuery('AdultData', () =>
    getServiceCategory('Adult')
  )
  const { data: childData } = useQuery('childData', () =>
    getServiceCategory('Child and Adolescent')
  )

  const {
    data: EventsData,
    status: EventsDataStatus,
    isLoading: EventsIsLoading,
    refetch: EventsIsRefetch
  } = useQuery(['EventsData'], async () => {
    return await KTGet('/api/events/public/', {
      current_date: new Date(),
      limit: 6,
      orderBy: 'id,desc',
      // category: 'Power of Zero',
      status: 'Published'
    })
  })

  // const { data: eventsData } = useQuery('eventsData')

  useEffect(() => {
    setDateIsLoading(true)
    dateRefetch()
    setBooking({
      ...booking,
      date: null,
      time: null
    })
    timeRef.current.selected = 'Select Date First'
  }, [booking.service])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setTimeSlotLoading(true)
    const response =
      booking.service &&
      (await KTPost('api/doctors/check_slot', {
        service_id: booking.service,
        schedule_date:
          /* formatISO(new Date(booking.date)) */ format(
            new Date(subDays(new Date(booking.date), 1)),
            'yyyy-MM-dd'
          ) + 'T16:00:00.000Z'
      }))
    let timeSlot = []
    let id = []
    response?.data?.result?.map(i => {
      if (
        timeSlot.includes(
          `${format(new Date(i.start_time), 'hh:mm a')} - ${format(
            new Date(i.end_time),
            'hh:mm a'
          )}`
        )
      ) {
        return
      } else {
        id.push(i.id)
        timeSlot.push(
          `${format(new Date(i.start_time), 'hh:mm a')} - ${format(
            new Date(i.end_time),
            'hh:mm a'
          )}`
        )
      }
    })
    setAvailableTimeSlot(timeSlot)
    setScheduleID(id)
    setTimeSlotLoading(false)
  }, [booking.date])

  useEffect(() => {
    const dates = dateData?.data?.result?.filter(i => {
      return (
        new Date(i.schedule_date) >
          new Date(format(new Date(), 'MM dd yyyy')) &&
        i.occupied_slot !== i.max_slot &&
        i.occupied_slot < i.max_slot
      )
    })
    setAvailableDates(
      dates?.map(i => {
        return formatInTimeZone(i.schedule_date, 'Asia/Taipei', 'yyyy-MM-dd')
      })
    )
    setDateIsLoading(false)
  }, [dateData])

  const handleSubmit = async () => {
    if (!booking.service) {
      toast.error('Please select a service', {
        id: 'service',
        position: 'top-center',
        ...KNThemeError
      })
    } else if (!booking.date) {
      toast.error('Please select a date', {
        id: 'date',
        position: 'top-center',
        ...KNThemeError
      })
    } else if (!booking.time) {
      toast.error('Please select a time', {
        id: 'time',
        position: 'top-center',
        ...KNThemeError
      })
    } else {
      const transaction_no = uuid()
      const response = await KTPost('api/booking/public', {
        service_id: parseInt(booking.service),
        schedule_id: parseInt(booking.time),
        transaction_no
      })
      if (response.data.success) {
        store.set('transaction_no', transaction_no)
        router.push('/booking')
      }
    }
  }
  const adult = AdultData?.data?.result
  const child = childData?.data?.result

  return (
    <>
      {/* MAIN NAV */}
      <HeaderNav />

      {/* Landing Page Section */}
      <>
        {/* <ImageSlider /> */}
        <div className='relative 2xl:pt-28 pt-24'>
          <Slider1 />
          <div className='absolute sm:top-[30%] top-[20%] py-28 w-full z-10'>
            <div className='max-w-screen-2xl mx-auto pb-24 sm:px-10 px-8'>
              <div className='max-w-3xl'>
                <p className='lg::text-3xl sm:text-2xl text-xl text-white font-semibold mb-3'>
                  ����������Ƶ, Inc.
                </p>
                <h2>
                  Enhancing Well-being
                </h2>
                <p className='sm:text-2xl text-lg text-white'>
                  We are at the forefront of mental health advocacy for {new Date().getFullYear() - new Date("January 15, 1950").getFullYear()} years
                  now, reaching out and changing millions of lives.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className='max-w-screen-2xl mx-auto pt-24 sm:px-10 px-8'>
          <div className='grid mb-10'>
            <div className='max-w-2xl mx-auto'>
              <h2 className='text-3xl leading-tight text-blue text-center'>
                ����������Ƶ, Inc.
              </h2>
              <p className='text-lg text-center'>
                ����������Ƶ is a private, non-stock, non-profit organization that
                provides premier Mental Health Services through Education,
                Advocacy, Intervention and Research.
              </p>
            </div>
          </div>

          <div className='grid sm:grid-cols-2 grid-cols-1 border-b border-gray-300 pb-5 align-middle max-w-7xl mx-auto'>
            <div className='col-span-1'>
              <h3 className='sm:mb-0 mb-2 sm:text-left text-center'>
                #WeAreHere
              </h3>
            </div>
            <div className='col-span-1'>
              <p className='mb-0 sm:text-right text-center'>
                Here for you. Here to stay.
              </p>
            </div>
          </div>

          <div className='grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1  gap-8 pt-10'>
            <div className='col-span-1'>
              <div className='bg-white drop-shadow-xl w-44 h-44 mx-auto rounded-full p-6 svg-wrap'>
                <ReactSVG src='assets/img/SVGAwareness.svg' />
              </div>
              <h4 className='text-lg text-center pt-8'>We raise awareness</h4>
            </div>
            <div className='col-span-1'>
              <div className='bg-white drop-shadow-xl w-44 h-44 mx-auto rounded-full p-6 svg-wrap'>
                <ReactSVG src='assets/img/SVGInspire.svg' />
              </div>
              <h4 className='text-lg text-center pt-8'>
                We inspire individuals
              </h4>
            </div>
            <div className='col-span-1'>
              <div className='bg-white drop-shadow-xl w-44 h-44 mx-auto rounded-full p-6 svg-wrap'>
                <ReactSVG src='assets/img/SVGFacilitate.svg' />
              </div>
              <h4 className='text-lg text-center pt-8 max-w-[200px] mx-auto'>
                We facilitate easy access to services
              </h4>
            </div>
            <div className='col-span-1'>
              <div className='bg-white drop-shadow-xl w-44 h-44 mx-auto rounded-full p-6 svg-wrap'>
                <ReactSVG src='assets/img/SVGStigma.svg' />
              </div>
              <h4 className='text-lg text-center pt-8'>We eradicate stigma</h4>
            </div>
          </div>
        </section>

        {/* STATS */}
        <Stats />

        {/* SERVICES */}
        <section className='max-w-screen-2xl mx-auto pb-24 sm:px-10 px-8'>
          <div className='grid md:grid-cols-2 grid-cols-1 gap-10 max-w-7xl mx-auto'>
            <div className='col-span-1 lg:py-10 py-8 lg:px-10 px-6 border border-gray-200 rounded-3xl'>
              <div className='max-w-[350px] mx-auto'>
                <Image
                  src={Service1}
                  alt='Education, Advocacy and Research Programs'
                />
              </div>
              <h2 className='text-center lg:text-2xl text-xl max-w-[400px] mx-auto my-4'>
                Education, Advocacy and Research Department
              </h2>
              <p className='text-center text-base'>
                Promoting mental health and preventing mental disorders through
                increasing public awareness campaigns and activities.
              </p>
              <div className='mt-10 text-center'>
                <Link href='/eard'>
                  <button className='btn-secondary rounded-full'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
            <div className='col-span-1 lg:py-10 py-8 lg:px-10 px-6 border border-gray-200 rounded-3xl'>
              <div className='max-w-[350px] mx-auto'>
                <Image
                  src={Service2}
                  alt='Education, Advocacy, and Research Programs'
                />
              </div>
              <h2 className='text-center lg:text-2xl text-xl max-w-[350px] mx-auto my-4'>
                Clinical and Intervention Services Department
              </h2>
              <p className='text-center text-base'>
                Providing mental health services to children, youth, and adults
                with emotional and behavioral problems and/or mental disorders.
              </p>
              <div className='mt-10 text-center'>
                <Link href='/cisd'>
                  <button className='btn-secondary rounded-full'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* NEWS AND UPDATES */}
        <section className='bg-gray-100 py-24'>
          <div className='max-w-screen-2xl mx-auto sm:px-10 px-8'>
            <h2 className='md:text-4xl text-3xl leading-tight text-blue text-center mb-12'>
              Latest ����������Ƶ and Updates
            </h2>
            {!isEmpty(EventsData?.data?.result) ? (
              <div className='grid lg:grid-cols-3 grid-cols-1 gap-10 lg:max-w-full max-w-xl mx-auto'>
                {EventsData?.data?.result?.map((events, index) => {
                  return (
                    <Link
                      className='cursor-pointer'
                      key={`events-${index}`}
                      href={`/news-events/${events?.slug}`}
                    >
                      <div className='col-span-1 cursor-pointer bg-white rounded-xl d-flex justify-items-stretch transition duration-300 ease-in-out hover:shadow-2xl'>
                        <div className=''>
                          <div className='relative w-full xl:h-[280px] lg:h-[230px] sm:h-[280px] h-[250px]'>
                            {events?.media?.path ? (
                              <Image
                                className='cursor-pointer object-cover rounded-t-xl'
                                src={events?.media?.path}
                                alt='����������Ƶ and Events'
                                layout='fill'
                              />
                            ) : null}
                          </div>
                          <div className='relative'>
                            <p className='text-white font-semibold uppercase absolute right-0 bg-[#95d50b] py-1 px-4'>
                              {format(new Date(events?.post_date), 'PP')}
                            </p>
                            <div className='py-8 px-8'>
                              <h3 className='text-lg pt-6'>{events.title}</h3>
                              <p className=''>{events.excerpt}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            ) : (
              <div className='px-2 text-center'>
                <div className='text-gray-500'>
                  No events posted.
                </div>
              </div>
            )}
            {/* <div className="">
              <pre>{JSON.stringify(EventsData?.data?.result,null,1)}</pre>
            </div> */}

            <div className='grid'>
              <div className='pt-12 text-center'>
                <Link href='/news-events'>
                  <button className='btn-secondary rounded-full py-4 px-8'>
                    View More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* BOOKING */}
        <section className='bg-blue lg:py-48 py-24'>
          <div className='max-w-screen-2xl mx-auto sm:px-10 px-8'>
            <div className='grid'>
              <h2 className='text-center lg:text-5xl text-4xl font-medium leading-10 text-white font-CantoraOne'>
                Book an appointment
              </h2>
              <p className='text-center lg:text-xl md:text-lg text-base text-white mb-10'>
                We will continue to enhance individual and collective well-being
                for a mentally healthy Philippines!
              </p>

              <div className='grid lg:grid-cols-10 md:grid-cols-2 grid-cols-1 gap-8 bg-white rounded-xl md:p-10 sm:p-8 p-6 lg:items-center items-end max-w-7xl mx-auto'>
                <div className='lg:col-span-3 col-span-1'>
                  <label
                    htmlFor='service'
                    className='font-semibold text-sm text-gray-500 uppercase tracking-wider block mb-3'
                  >
                    Service
                  </label>
                  <select
                    name='service'
                    id='service'
                    onChange={e =>
                      setBooking({
                        ...booking,
                        service: e.target.value
                      })
                    }
                    className='form-control'
                  >
                    <option value=''>Select Service</option>
                    <optgroup readOnly label='Adult'>
                      {adult?.map((i, key) => {
                        return (
                          <option title={i.description} value={i.id} key={key}>
                            {i.name}
                          </option>
                        )
                      })}
                    </optgroup>
                    <optgroup label='Child and Adolescents'>
                      {child?.map((i, key) => {
                        return (
                          <option title={i.description} value={i.id} key={key}>
                            {i.name}
                          </option>
                        )
                      })}
                    </optgroup>
                  </select>
                </div>
                <div className='lg:col-span-3 col-span-1'>
                  <label className='font-semibold text-sm text-gray-500 uppercase tracking-wider block mb-3'>
                    Date
                  </label>
                  <KNDatePicker
                    key={booking.service}
                    setDate={date => {
                      setBooking({ ...booking, date: date })
                    }}
                    disabled={booking.service && !dateIsLoading ? false : true}
                    placeholderText={
                      booking.service
                        ? !isEmpty(availableDates)
                          ? 'Select Date'
                          : dateIsLoading
                          ? 'Loading ...'
                          : 'No Available Slot'
                        : 'Select Service First'
                    }
                    includeDates={availableDates}
                  />
                </div>
                <div className='lg:col-span-3 col-span-1'>
                  <label className='font-semibold text-sm text-gray-500 uppercase tracking-wider block mb-3'>
                    Time
                  </label>
                  <select
                    name='sched_time'
                    id='sched_time'
                    ref={timeRef}
                    onChange={e => {
                      setBooking({ ...booking, time: e.target.value })
                    }}
                    className='form-control'
                  >
                    <option value=''>
                      {!booking.service
                        ? 'Select Service First'
                        : !booking.date
                        ? 'Select Date First'
                        : isFetching || isTimeSlotLoading
                        ? 'Loading...'
                        : availableTimeSlot.length > 0
                        ? 'Select Time'
                        : 'No available time slot'}
                    </option>
                    {availableTimeSlot?.map((i, key) => {
                      return (
                        <option key={key} value={scheduleID[key]}>
                          {i}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='col-span-1 text-center'>
                  <button
                    className='btn btn-secondary rounded-full p-3 mx-auto lg:block hidden'
                    type='submit'
                    onClick={() => handleSubmit()}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-7 h-7 text-white'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
                      />
                    </svg>
                  </button>
                  <button
                    type='submit'
                    onClick={() => handleSubmit()}
                    className='btn btn-secondary lg:hidden block py-[14px] px-[30px]'
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

      <Footer />

      <KNToaster />
    </>
  )
}

export default ����������ƵView
